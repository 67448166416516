<!--
  Uses: Icon slot with self spacing
  <template slot="icon">
    <i class="flaticon-reading-book text-2xl mr-2"></i>
  </template>
-->
<template>
  <div class="tab-component">
    <slot></slot>
    <div class="block">
      <div class="inline-block border-b border-b-solid border-gray-300" v-for="(item, index) in items"  :key="index">
        <template>
          <router-link
              class="mx-3 py-2 text-gray-500 pointer flex justify-center align-flex-start mt-4"
              :to="{name: item.path}"
            
              active-class="active"
              @click.native="onClick"
            >
              <slot name="icon" :item="item"></slot>
              <span :style="{color: item.color}" class="text-uppercase text-base ml-3">{{ item.name }} </span>
            </router-link>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabRouter",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
      onClick() {
          this.$emit('onClick')
      }
  }

};
</script>
<style lang="scss">
.tab-component .active {
  font-weight: bold;
  border-bottom: 1px solid rgb(73, 73, 73);
  margin-bottom: -1px;
}
</style>
